export const apiPaths = {
  auth: () => '/api/auth',
  user: () => '/api/user',
  profileOnboard: () => '/api/profile/onboard',
  profileUpdate: () => '/api/profile',
  profilePartner: () => '/api/profile/partner',
  myAssets: () => '/api/my-assets',
  myAsset: (id: string) => `/api/my-assets/${id}`,
  assetCreate: () => '/api/my-assets/create',
  agreementTypes: () => '/api/agreement-types',
  allAgreementTypes: () => '/api/agreement-types/all',
  myFamilies: () => '/api/my-family',
  myFamily: (id: string) => `/api/my-family/${id}`,
  familyCreate: () => '/api/my-family/create',
  mySectionAssets: () => '/api/my-assets/sections',
  mySectionFamily: () => '/api/my-family/sections',
  sectionModule: (kind: string) => `/api/first-module/${kind}`,
  sectionModuleStart: (kind: string) => `/api/first-module/start/${kind}`,
  sectionModuleComplete: (kind: string) => `/api/first-module/complete/${kind}`,
  userQuiz: (id: string) => `/api/user-quiz/${id}`,
  quiz: (slug: string) => `/api/quiz/${slug}`,
  answer: (id: string) => `/api/answer/${id}`,
  answerReview: (id: string) => `/api/answer/review/${id}`,
  paymentsCreateLink: () => '/api/payments/checkout-session',
  paymentsVerifySession: (id: string) => `/api/payments/verify/${id}`,
  invitesCreate: () => '/api/invites',
  prenup: () => '/api/prenups/content',
  prenupUpdateContent: () => '/api/prenup/update-content',
  financialViews: () => '/api/alignment/financial-views',
  premaritalProperty: (partner: boolean) => `/api/alignment/premarital-property/${partner}`,
  maritalPropertyPartner: (partner: boolean) => `/api/alignment/marital-property/${partner}`,
  maritalProperty: () => '/api/marital-property',
  maritalPropertyCreate: () => '/api/marital-property/create',
  maritalPropertyId: (id: string) => `/api/marital-property/${id}`,
  maritalPropertyAgree: (id: string) => `/api/marital-property/agree/${id}`,
  maritalPropertyUpdateAll: () => '/api/marital-property/update-all',
  lawyerAuth: () => '/api/lawyers/auth',
  lawyersSelelctions: () => '/api/lawyer-selections',
  lawyersSelelction: (id: string) => `/api/lawyer-selections/${id}`,
  lawyerSelectionsChooseOwn: () => '/api/lawyer-selections/choose-own',
  lawyerReviewDash: () => '/api/lawyer-review',
  lawyerReviewStart: () => '/api/lawyer-review/start',
  lawyerReivewComplete: () => '/api/lawyer-review/complete',
  lawyerReviewFinalize: () => '/api/lawyer-review/finalize',
  lawyerReviewEgagement: () => '/api/lawyer-review/engagement',
  lawyerReviewAdvisory: () => '/api/lawyer-review/advisory',
  lawyerReviewClient: (token: string) => `/api/lawyer-review/client/${token}`,
  lawyerReviewDocs: (clientId: string, slug: string, partner: boolean = false) =>
    `/api/lawyer-review/client/${clientId}/docs/${slug}?partner=${partner}`,
  laywerComments: (clientId: string) => `/api/lawyer-comments/${clientId}`,
  lawyerUpdateContent: (clientId: string) => `/api/lawyer-review/client/${clientId}/update-content`,
  lawyerReviewUpdateHtml: () => '/api/lawyer-review/update-content',
  lawyerViewed: (clientId: string) => `/api/clients/${clientId}/lawyer-review/viewed`,
  lawyerAdvisoryLetter: (clientId: string) =>
    `/api/lawyer-review/client/${clientId}/advisory-letter`,
  lawyerReviewSendToPartner: (clientId: string) =>
    `/api/lawyer-review/client/${clientId}/send-to-partner`,
  userComments: () => '/api/user-comments',
  userComment: (id: string) => `/api/user-comments/${id}`,
  lawyerReviewComplete: (clientId: string) => `/api/clients/${clientId}/lawyer-review/complete`,
  lawyerReviewUserFinalize: () => '/api/lawyer-review/user-finalize',
  userLawyer: () => '/api/user-lawyer',
  clientDocs: (clientId: string, slug: string) => `/api/clients/${clientId}/documents/${slug}`,
  lawyerDashboardClients: () => '/api/clients',
  lawyerDownloadAgreement: (clientId: string) => `/api/clients/${clientId}/docs/download-prenup`,
  downloadAgreement: () => '/api/docs/download-prenup',
  signAgreement: () => '/api/prenups/signature-url',
  signDoc: (kind: string) => `/api/documents/${kind}/signature-url`,
  downloadDoc: (kind: string) => `/api/docs/download-doc/${kind}`,
}

export type APIKeys = keyof typeof apiPaths
